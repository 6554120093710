interface DebounceOptions {
  leading?: boolean;
}

/** Given any async function, returns a debounced version of that function */
export function debounce<T extends (...args: any[]) => Promise<any>>(fn: T, wait = 0, options: DebounceOptions = {}) {
  let lastCallAt: number = 0;
  let deferred: DeferredPromise | null;
  let timer: NodeJS.Timeout;
  let pendingArgs: unknown[] = [];
  return function debounced(...args: Parameters<T>) {
    const currentTime = Date.now();

    const isCold = !lastCallAt || (currentTime - lastCallAt) > wait;

    lastCallAt = currentTime;

    if (isCold && options.leading) {
      return fn.call(this, ...args);
    }

    if (deferred) {
      clearTimeout(timer);
    } else {
      deferred = defer();
    }

    pendingArgs.push(args);
    timer = setTimeout(flush.bind(this), wait);

    return deferred.promise;
  }

  function flush() {
    const thisDeferred = deferred!;
    clearTimeout(timer);

    fn.apply(this, pendingArgs[pendingArgs.length - 1])
      .then(thisDeferred.resolve, thisDeferred.reject);

    pendingArgs = [];
    deferred = null;
  }
}

interface DeferredPromise {
  promise: Promise<any>;
  resolve: (value: any) => void;
  reject: (reason: any) => void;
}
function defer() {
  const deferred = {} as DeferredPromise;
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred;
}

declare global {
  interface Window {
    $: JQueryStatic & JQuery;
  }
}

export function fakePromiseWrapper(): Promise<void>;
export function fakePromiseWrapper<T>(obj: T): Promise<T>;
export function fakePromiseWrapper<T>(obj?: T): Promise<T | void> {
  return new Promise<T | void>((resolve) => {
    setTimeout(() => {
      if (obj === undefined) resolve();
      else resolve(obj);
    }, Math.random() * 2500 + 500);
  });
}

export function unwrapInputEvent(callback: (value: string) => void) {
  return (event: Event & { currentTarget: HTMLInputElement | HTMLButtonElement }) => callback(event.currentTarget.value);
}

export function hash(v: number) {
  const state = v * 747796405 + 2891336453;
  const word = ((state >> ((state >> 28) + 4)) ^ state) * 277803737;
  return (word >> 22) ^ word;
}

export function lchToRgb(l: number, c: number, h: number) {
  const chromaX = Math.round(c * Math.cos((h * Math.PI) / 180));
  const chromaZ = Math.round(c * Math.sin((h * Math.PI) / 180));

  const [xw, yw, zw] = [0.94811, 1, 1.07304];
  const fy = (l + 16) / 116;
  const fx = fy + chromaX / 500;
  const fz = fy - chromaZ / 200;

  const [x, y, z] = [
    [xw, fx],
    [yw, fy],
    [zw, fz],
  ].map(
    ([w, f]) => w * (f ** 3 > 0.008856 ? f ** 3 : (f - 16 / 116) / 7.787)
  );

  const rgb = [
    x * 3.2406 - y * 1.5372 - z * 0.4986,
    -x * 0.9689 + y * 1.8758 + z * 0.0415,
    x * 0.0557 - y * 0.204 + z * 1.057,
  ]
    .map((v) =>
      v > 0.0031308 ? 1.055 * Math.pow(v, 1 / 2.4) - 0.055 : 12.92 * v
    )
    .map((v) => Math.round(Math.max(Math.min(v, 1), 0) * 255));
  return rgb;
}