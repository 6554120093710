<script lang="ts" context="module">
  import { createInputModalStore } from "./stores";

  type ProceedModalData = {
    title: string;
    messageHtml: string;
    proceedText: string;
    backText: string;
  };
  export const proceedModal = createInputModalStore<
    ProceedModalData,
    boolean
  >();
</script>

<script lang="ts">
  import BasicModal from "./BasicModal.svelte";

  export let data: ProceedModalData;
</script>

<BasicModal title={data.title} id="error-modal" store={proceedModal}>
  {@html data.messageHtml}

  <svelte:fragment slot="footer">
    <button
      class="btn btn-primary"
      type="button"
      on:click={() => proceedModal.confirm(true)}
    >
      {data.proceedText}
    </button>
    <button class="btn btn-danger" type="button" on:click={proceedModal.cancel}>
      {data.backText}
    </button>
  </svelte:fragment>
</BasicModal>
