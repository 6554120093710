import { browser } from "$app/environment";
import { apiFetch } from "$lib/api";
import { writable } from "svelte/store";
import { userPromise } from "$lib/stores/index";

export type SiteSettings = {
  version: number;
  disableBGBoxes?: boolean;
}
const defaultSiteSettings = { version: 1 };

export const siteSettings = writable<SiteSettings>(getLocalSiteSettings());

let userIsLoaded = false;
userPromise.subscribe($userPromise => $userPromise.then(user => {
  if (!user) {
    userIsLoaded = false;
    return;
  }
  if (user.config) siteSettings.set(user.config);
  userIsLoaded = true;
}));
siteSettings.subscribe(async (value) => {
  if (!browser) return;
  localStorage.setItem("siteSettings", JSON.stringify(value));
  if (userIsLoaded) {
    await apiFetch("PUT", "/api/user/config", value);
  }
});

function getLocalSiteSettings(): SiteSettings {
  if (!browser) return defaultSiteSettings;
  const localString = localStorage.getItem("siteSettings");
  if (!localString) return defaultSiteSettings;
  try {
    const settings = JSON.parse(localString);
    if (typeof settings !== "object" || typeof settings.version !== "number") return defaultSiteSettings;
    return settings;
  } catch {
    return defaultSiteSettings;
  }
}

