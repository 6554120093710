import { browser } from "$app/environment";
import { writable, type Writable } from "svelte/store";

export interface Initable<T> extends Writable<T> {
  init: () => void;
}

export function initable<T>(value: T, init: () => T): Initable<T> {
  const store = writable(value);
  let initialized = false;
  return {
    ...store,
    init() {
      if (!browser) return;
      if (initialized) return;
      try {
        store.set(init());
        initialized = true;
      } catch (e) {
        console.error("Failed to initialize store:", e);
      }
    }
  }
}