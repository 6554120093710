<script lang="ts" context="module">
  import { createModalStore } from "./stores";

  type ErrorModalData = {
    title: string;
    messageHtml: string;
  };
  export const errorModal = createModalStore<ErrorModalData>();

  export function showError(title: string, messageHtml: string) {
    console.error(title, messageHtml);
    return errorModal.openModal({ title, messageHtml });
  }

  export function uploadError(body: { message: string; status: number }) {
    return showError(
      `${body.status} Upload error`,
      body.message || `Unknown error (${body.status})!`
    );
  }
</script>

<script lang="ts">
  import BasicModal from "./BasicModal.svelte";

  export let data: ErrorModalData;

  const { closeModal } = errorModal;
</script>

<BasicModal title={"Error: " + data.title} id="error-modal" store={errorModal}>
  {@html data.messageHtml}
  <button
    class="btn btn-primary"
    on:click={closeModal}
    slot="footer"
    type="button"
  >
    OK
  </button>
</BasicModal>
