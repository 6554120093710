<script lang="ts" context="module">
  import { createInputModalStore } from "./stores";
  export type RateLimitModalData = {
    retryAfter: number;
  };
  export const rateLimitModal = createInputModalStore<
    RateLimitModalData,
    boolean
  >();
</script>

<script lang="ts">
  import BasicModal from "./BasicModal.svelte";
  import { onDestroy } from "svelte";

  export let data: RateLimitModalData;

  let currentTime = Math.floor(Date.now());
  let retrying = false;
  $: targetTime = Date.now() + data.retryAfter + 1;
  $: timeToRetry = Math.max(Math.floor((targetTime - currentTime) / 1000), 0);

  const ticker = setInterval(() => {
    currentTime = Math.floor(Date.now());
    if (currentTime > targetTime && retrying) {
      rateLimitModal.confirm(true);
    }
  }, 1000);
  onDestroy(() => {
    clearInterval(ticker);
  });

  function enableRetry() {
    retrying = true;
    if (currentTime > targetTime) {
      rateLimitModal.confirm(true);
    }
  }
</script>

<BasicModal
  title={"Error: Rate Limit Exceeded"}
  id="rate-limit-modal"
  store={rateLimitModal}
>
  <p>
    You have exceeded the rate limit for that action. Press the "Wait and Retry"
    button to automatically try again after the time has passed.
  </p>
  <p>If you would like to cancel the action, press the "Cancel" button.</p>

  <p>
    {#if retrying}
      Automatically retrying
    {:else}
      You may try again
    {/if}
    {#if timeToRetry}
      in
      {timeToRetry} seconds.{retrying ? ".." : ""}
    {:else}
      now.
    {/if}
  </p>

  <svelte:fragment slot="footer">
    {#if retrying}
      <button class="btn btn-primary disabled" type="button" disabled>
        Waiting...
      </button>
    {:else}
      <button class="btn btn-primary" type="button" on:click={enableRetry}>
        {#if timeToRetry}
          Wait and Retry
        {:else}
          Retry Now
        {/if}
      </button>
    {/if}
    <button
      class="btn btn-danger"
      type="button"
      on:click={rateLimitModal.cancel}
    >
      Cancel
    </button>
  </svelte:fragment>
</BasicModal>
