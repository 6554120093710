import { derived, writable } from "svelte/store";
import { browser } from "$app/environment";
import { apiFetch } from "$lib/api";
import type { APIResponse, Group, Tupper } from "tupperschema";
import { initable } from "$lib/stores/initable";

export const layout = writable({
  showBanner: false,
});

export const userPromise = writable<Promise<APIResponse<"/api/user", "GET"> | null>>(new Promise(() => { }), (set) => {
  if (!browser) return set(Promise.resolve(null));
  set(getUser());
});
export const tuppersPromise = initable(new Promise<APIResponse<"/api/tuppers", "GET">>(() => { }), async () => {
  const { ok, body } = await apiFetch("GET", "/api/tuppers");
  if (ok) return body;
  throw new Error("Failed to fetch Tuppers from API: " + body.message);
});
export const groupsPromise = initable(new Promise<APIResponse<"/api/groups", "GET">>(() => { }), async () => {
  const { ok, body } = await apiFetch("GET", "/api/groups");
  if (ok) return body;
  throw new Error("Failed to fetch Groups from API: " + body.message);
});
export const premiumPromise = initable(new Promise<APIResponse<"/api/user/premium", "GET">>(() => { }), async () => {
  const { ok, body } = await apiFetch("GET", "/api/user/premium");
  if (ok) return body;
  throw new Error("Failed to fetch Premium status from API: " + body.message);
});

export const canonUser = derived(userPromise, ($userPromise, set: (user: APIResponse<"/api/user", "GET"> | null) => void) => { $userPromise.then(set) }, null);
export const canonTuppers = derived(tuppersPromise, ($tuppersPromise, set: (tuppers: Tupper[]) => void) => { $tuppersPromise.then(set) }, []);
export const canonGroups = derived(groupsPromise, ($groupsPromise, set: (groups: Group[]) => void) => { $groupsPromise.then(set) }, []);
export const canonPremium = derived(premiumPromise, ($premiumPromise, set: (premium: APIResponse<"/api/user/premium", "GET"> | null) => void) => { $premiumPromise.then(set) }, null);

export async function getUser() {
  const user = await apiFetch("GET", `/api/user`, undefined, { redirect: false, handleErrors: false }).then((res) => {
    if (res.ok) return res.body;
    console.error("Failed to fetch user from API.");
    return null;
  }).catch((e) => {
    if (e.message == "Invalid token") {
      console.error("Failed to fetch user, invalid token.");
      return null;
    }
    console.error("Failed to fetch user, unknown error:", e);
    throw e;
  });
  return user;
}