<script lang="ts">
  import type { ModalStore, InputModalStore } from "./stores";

  export let id: string;
  export let store: ModalStore | InputModalStore;
  export let title: string;
  export let noSlide = false;

  $: show = $store.show;

  let clickStartedOutsideModal = false;
</script>

<svelte:window
  on:keyup={(e) => {
    if (show && e.key === "Escape") store.closeModal();
  }}
/>

<!-- Modal -->
<div
  {id}
  class="modal"
  tabindex="-1"
  aria-hidden="true"
  aria-labelledby="{id}-label"
  class:show
  on:pointerdown|self={() => (clickStartedOutsideModal = true)}
  on:pointerup|self={() => {
    if (clickStartedOutsideModal) store.closeModal();
  }}
  on:pointerup={() => (clickStartedOutsideModal = false)}
>
  <div class="modal-dialog" class:no-animation={noSlide}>
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="{id}-label">{title}</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          on:click={store.closeModal}
        />
      </div>
      <div class="modal-body"><slot /></div>
      {#if $$slots.footer}
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @keyframes slide-down {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  .modal {
    display: none;
  }
  .modal.show {
    display: block;
  }
  .modal-content {
    max-height: 90vh;
  }
  .modal-body {
    overflow-y: auto;
  }
  .modal-dialog {
    animation: slide-down 0.3s ease-out;
    &.no-animation {
      animation: none;
    }
  }
</style>
