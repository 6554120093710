import { writable } from "svelte/store";

export type ModalStore = ReturnType<typeof createModalStore>;
export type InputModalStore = ReturnType<typeof createInputModalStore>;

export function createModalStore<T>() {
  const initialData = { data: null, show: false, render: false } as const;
  const { subscribe, update, set } = writable<
    { data: T | null, show: boolean; render: boolean }
  >(initialData);

  let resolve: () => void = () => { };

  return {
    subscribe,
    set,
    openModal,
    closeModal,
    libraryShow,
    libraryHide,
  };

  function openModal(modalData: T) {
    update(() => ({ data: modalData, show: true, render: true }));
    return new Promise<void>((res, rej) => { resolve = res; });
  }
  function closeModal() {
    update((curr) => ({ ...curr, show: false, render: false }));
    resolve();
  }
  function libraryShow() {
    update((curr) => ({ ...curr, show: true, render: true }));
  }
  function libraryHide() {
    update((curr) => ({ ...curr, show: false, render: true }));
  }
}

export function createInputModalStore<T, R>() {
  const initialData = { data: null, show: false, render: false } as const;
  const { subscribe, update, set } = writable<
    { data: T | null, show: boolean; render: boolean }
  >(initialData);

  let resolve: (value: R | null) => void = (v) => { };

  return {
    subscribe,
    set,
    getInput,
    confirm,
    cancel,
    closeModal: cancel,
    libraryShow,
    libraryHide,
  };

  function getInput(modalData: T): Promise<R | null> {
    update(() => ({ data: modalData, show: true, render: true }));
    return new Promise<R | null>((res, rej) => { resolve = res; });
  }
  function confirm(value: R) {
    update((curr) => ({ ...curr, show: false, render: false }));
    resolve(value);
  }
  function cancel() {
    update((curr) => ({ ...curr, show: false, render: false }));
    resolve(null);
  }
  function libraryShow() {
    update((curr) => ({ ...curr, show: true, render: true }));
  }
  function libraryHide() {
    update((curr) => ({ ...curr, show: false, render: true }));
  }
}