import { proceedModal } from "$lib/components/modals/ProceedModal.svelte"; // Using just $lib/components/modals creates a circ depend
import { showError } from "$lib/components/modals/ErrorModal.svelte"; // Using just $lib/components/modals creates a circ depend
import { OAUTH_URL } from "$lib/constants";
import { debounce } from "$lib/utils";
import type { APISchema, APIRequest, APIResponse } from "tupperschema";
import { rateLimitModal } from "$lib/components/modals/RateLimitModal.svelte";

const refresh = debounce(() => fetch("/auth/refresh", { credentials: "include" }), 200);

type APISuccessResult<T> = { res: Response, ok: true, body: T };
type APIErrorResult = { res: Response, ok: false, body: { status: number, code: string, message: string, retryAfter?: number } };
type APIResult<T> = APISuccessResult<T> | APIErrorResult;

export type APIFetchResult<Route extends keyof APISchema, Method extends keyof APISchema[Route]> = Promise<APIResult<APIResponse<Route, Method>>>;

function initFetch(route: string, method: string, content?: { form: Record<string, string | Blob> } | object) {
  const init = { method, credentials: "include" } as const;
  if (!content) {
    return fetch(route, init);
  }
  if ("form" in content) {
    const form = new FormData();
    for (const [key, value] of Object.entries(content.form)) {
      if (value === undefined) continue;
      if (value instanceof Blob || typeof value !== "object") form.append(key, value);
      else form.append(key, JSON.stringify(value));
    }
    return fetch(route, { ...init, body: form });
  }
  return fetch(route, { ...init, headers: { "Content-Type": "application/json" }, body: JSON.stringify(content) });
}

type AnyRoute = keyof APISchema;
type Methods = {
  [K in AnyRoute]: keyof APISchema[K];
};
type AnyMethod = Methods[AnyRoute];
type AnyRequestBody = {
  [K in AnyRoute]: {
    [M in Methods[K]]: APIRequest<K, M>;
  }[Methods[K]];
}[AnyRoute];

interface ApiFetchOptions {
  redirect?: boolean;
  retrying?: boolean;
  handleErrors?: boolean;
}

async function innerApiFetch<Route extends AnyRoute, Method extends Methods[Route]>(
  method: Method,
  route: Route,
  content?: APIRequest<Route, Method>,
  { redirect, retrying, handleErrors }: ApiFetchOptions = {}
): APIFetchResult<Route, Method> {
  try {
    const res = await initFetch(route, method as AnyMethod, content as AnyRequestBody);
    if (res.status == 401) {
      if (retrying) {
        // refresh worked but token still broken
        if (redirect) {
          await redirectToLogin();
          return responseError(401, "No refresh token");
        } else {
          await fetch("/auth/logout", { credentials: "include" })
        }
        return { res, ok: false, body: { status: 401, code: "N/A", message: "Invalid token" } };
      }
      const refreshRes = await refresh();
      if (!refreshRes.ok) {
        if (redirect) {
          await redirectToLogin();
          return responseError(401, "No refresh token");
        }
        return { res, ok: false, body: { status: 401, code: "N/A", message: "Refresh failed and redirect is disabled." } };
      }
      return innerApiFetch(method, route, content, { retrying: true, redirect, handleErrors });
    }

    let body: any = null;
    if (res.status !== 204) {
      try {
        body = await res.json();
      } catch (e) {
        return { res, ok: false, body: { status: res.status, code: "N/A", message: "Invalid API response" } };
      }
    }

    return { res, ok: res.ok, body };
  } catch (e) {
    return responseError(469, "Connection error.");
  }
}

export async function handleApiErrors(apiRes: APIErrorResult) {
  if (apiRes.res.status === 569) {
    await showError("Unknown Error", "An unclassified error occurred. No one knows what might happen next.  The <a href='https://discord.gg/Z4BHccHhy3'>support server</a> should be informed before the final sunset ends and the eternal night begins.");
  }
  else if (apiRes.res.status >= 500) {
    await showError("Server Error", "An error occurred on the server. Please try again later, or report this in the <a href='https://discord.gg/Z4BHccHhy3'>support server</a> if this issue persists.");
  } else if (apiRes.res.status == 401) {
    await showError("Invalid Login", "Your session has expired, please try logging in again.");
  } else if (apiRes.res.status == 429 && apiRes.body.retryAfter) {
    if (await rateLimitModal.getInput({ retryAfter: apiRes.body.retryAfter })) {
      return true;
    }
  } else if (apiRes.res.status == 469) {
    await showError("Connection Error", "There was an error connecting to the server. Please check your connection and try again.");
  } else if (apiRes.res.status >= 400) {
    if (apiRes.body.code == "ValidationError") {
      await showError("Validation Error", "The API rejected the request. Please fix the following validation errors before trying again:\n" + apiRes.body.message);
    } else {
      await showError("Error", apiRes.body.message);
    }
  }
  console.error(apiRes);
  return false;
}

export async function apiFetch<Route extends AnyRoute, Method extends Methods[Route]>(
  method: Method,
  route: Route,
  content?: APIRequest<Route, Method>,
  { redirect = true, retrying = false, handleErrors = true }: ApiFetchOptions = {}
): APIFetchResult<Route, Method> {
  const res = await innerApiFetch(method, route, content, { redirect, retrying, handleErrors });
  if (!res.ok && handleErrors) {
    if (await handleApiErrors(res)) return apiFetch(method, route, content, { redirect, retrying, handleErrors });
  }
  return res;
}

export function unwrapOrThrow<T>(res: APIResult<T>) {
  if (!res.ok) throw new Error(res.body.message);
  return res.body;
}

export async function redirectToLogin({ redirect = document.location.pathname, error = true }: { redirect?: string, error?: boolean } = {}) {
  document.cookie = `redirect=${encodeURIComponent(redirect)}; path=/;`;
  if (!error) {
    window.location.href = OAUTH_URL();
    return true;
  }

  if (await proceedModal.getInput({
    title: "Must be Signed In",
    messageHtml: "You must be signed in to proceed. You will be redirected to a Discord page where you can sign in with your Discord account before returning to Tupperbox.app.",
    proceedText: "Sign in with Discord",
    backText: "Go back",
  })) {
    window.location.href = OAUTH_URL();
    return true;
  }

  return false; // not redirecting
}

export function responseError(status: number, message: string, code: string = "N/A") {
  if (status < 200 || status > 599) status = 569;
  return { res: new Response(null, { status, statusText: message }), ok: false as const, body: { status, code, message } };
}